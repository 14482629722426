<template>
  <v-snackbar v-model="resource.display" timeout="3000">
    {{ resource.message }}

    <template v-slot:action="{ attrs }">
      <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
        Fechar
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  props: {
    resource: {
      type: [Array, Object, String],
    },
  },
};
</script>

<style>
</style>
